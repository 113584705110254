import React from "react";

const Rank = () => {
    return(
        <div>
            <div className="white f3">
                {'Rudeus, your current rank is...'}
            </div>
            <div className="white f1">
                {'#17'}
            </div>
        </div>
    );
}

export default Rank;